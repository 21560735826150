import * as React from 'react';
import type { PageProps } from 'gatsby';
import SearchLayout from '../layouts/search';

const SearchPage = (data: PageProps) => {
  const { location } = data;
  const searchQuery = new URLSearchParams(location.search).get('keywords') || '';
  return <SearchLayout searchQuery={searchQuery} />;
};

export default SearchPage;
